import { TrackingDataProps } from '@sport1/types/web'

export default function getTrackingValue(
    tracking: TrackingDataProps[] | undefined,
    key: string
): string | undefined {
    if (!tracking) {
        return undefined
    }
    return tracking.find(entry => entry.key === key)?.value
}
