import React, { createContext, ReactElement, useCallback, useContext } from 'react'
import { adTrackingExtraData } from './trackingProviderConsts'
import getConsentInfo from '@/utils/consent/getConsentInfo'

export type TrackingDataItem = { key: string; value: string }
export type TrackingData = TrackingDataItem[]

export interface MappedTrackingData {
    [key: string]: string | undefined
}

type MappedAdditionalTrackingData = MappedTrackingData & {
    additionalTrackingData?: MappedTrackingData
}

type IvwPiCallbackData = {
    ivw_context?: string
    ivw_co?: string
    additionalTrackingData?: MappedTrackingData
}

type CampaignCallbackData = {
    event: string
    campaign: string
    target_url: string
    additionalTrackingData?: MappedTrackingData
}

export type TrackingState = {
    trackingData: MappedTrackingData
    trackIvwInfo: (data?: IvwPiCallbackData) => void
    trackIvwView: (data?: IvwPiCallbackData, loadAllAdSlots?: boolean) => void
    trackInteraction: (
        newTrackingData?: MappedTrackingData,
        ignorePageTrackingData?: boolean
    ) => void
    trackCampaign: (data: CampaignCallbackData) => void
    trackTeaserImpression: (data?: MappedTrackingData) => void
    trackTeaserClick: (data?: MappedTrackingData) => void
    trackAdView: (data: MappedAdditionalTrackingData) => void
    trackAdViewOncePerPageView: (data: MappedAdditionalTrackingData) => void
    trackAdInteraction: (data: MappedAdditionalTrackingData) => void
}

const TrackingContext = createContext<TrackingState>({
    trackingData: {},
    trackIvwInfo: () => undefined,
    trackIvwView: () => undefined,
    trackInteraction: () => undefined,
    trackCampaign: () => undefined,
    trackTeaserImpression: () => undefined,
    trackTeaserClick: () => undefined,
    trackAdView: () => undefined,
    trackAdViewOncePerPageView: () => undefined,
    trackAdInteraction: () => undefined,
})

type TrackingProviderProps = {
    tracking?: TrackingData
    adTargeting?: MappedTrackingData
    children?: ReactElement | ReactElement[]
}

export const PAGE_REFERRING_WIDGET_ID = 'PAGE_REFERRING_WIDGET_ID'

export const mapAdTrackingData = (
    tracking?: MappedTrackingData,
    trackingList?: { searchKey: string; setKey: string }[]
): MappedTrackingData => {
    if (!tracking || !trackingList || trackingList.length === 0) return {}
    return trackingList.reduce((prev, { searchKey, setKey }) => {
        return {
            ...prev,
            ...(searchKey in tracking && { [setKey]: tracking[searchKey] }),
        }
    }, {} as MappedTrackingData)
}

export const mapTrackingData = (tracking?: TrackingData): MappedTrackingData => {
    const mappedTrackingData: MappedTrackingData = {}
    tracking?.map(trackingValue => {
        mappedTrackingData[trackingValue.key] = trackingValue.value
    })
    return mappedTrackingData
}

export const mapPageTrackingData = (tracking?: TrackingData): MappedTrackingData => {
    const mappedTrackingData = mapTrackingData(tracking)
    if (!mappedTrackingData.page_withvideo) {
        mappedTrackingData.page_withvideo = 'false'
    }
    return mappedTrackingData
}

export const TrackingProvider = ({
    tracking,
    adTargeting,
    children,
}: TrackingProviderProps): ReactElement => {
    const initial = React.useRef(true)
    const trackingData = mapTrackingData(tracking)
    const pageTrackingData = mapPageTrackingData(tracking)
    const additionalTrackingAdData = mapAdTrackingData(adTargeting, adTrackingExtraData)
    const teaserImpressionKeys = React.useRef<string[]>([])

    const trackPageView = useCallback(() => {
        teaserImpressionKeys.current = []
        window.dataLayer = window.dataLayer || []

        getConsentInfo().then(consentInfo => {
            window.dataLayer.push({
                event: 'view.page',
                ...consentInfo,
            })
        })
    }, [])

    const trackTeaserImpression = useCallback(
        ({
            advertiser_id,
            campaign,
            creation,
            format,
            target_url,
            url,
            variant,
            teaser_editorial_campaign,
        }: MappedTrackingData = {}) => {
            window.dataLayer = window.dataLayer || []
            const key = `${format}_${variant}`
            if (campaign && creation && format && !teaserImpressionKeys.current.includes(key)) {
                teaserImpressionKeys.current.push(key)
                window.dataLayer.push({
                    event: 'teaser.impression',
                    campaign,
                    creation,
                    variant,
                    format,
                    advertiser_id,
                    target_url: url || target_url,
                    teaser_editorial_campaign,
                })
            }
        },
        []
    )

    const trackTeaserClick = useCallback(
        ({
            advertiser_id,
            campaign,
            creation,
            format,
            target_url,
            url,
            variant,
            teaser_editorial_campaign,
        }: MappedTrackingData = {}) => {
            window.dataLayer = window.dataLayer || []
            if (campaign && creation && format) {
                window.dataLayer.push({
                    event: 'teaser.click',
                    campaign,
                    creation,
                    variant,
                    format,
                    advertiser_id,
                    target_url: url || target_url,
                    teaser_editorial_campaign,
                })
            }
        },
        []
    )

    const trackIvwInfo = useCallback(
        (data: IvwPiCallbackData = {}) => {
            const { additionalTrackingData, ...rest } = data
            const trackingData = {
                ...pageTrackingData,
                ...additionalTrackingData,
                ...rest,
            } as MappedAdditionalTrackingData

            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'ivw.info',
                ivw_co: trackingData.page_slug || location.pathname,
                ivw_cp: trackingData.page_ivw_code,
                ivw_sc: 'yes',
                ivw_context: null,
                ...rest,
            })
        },
        [pageTrackingData]
    )

    /**
     * The virtual ivw page impression (interaction on a single page).
     */
    const trackIvwView = useCallback(
        (data: IvwPiCallbackData = {}, loadAllAdSlots = true) => {
            const { additionalTrackingData, ...rest } = data
            const trackingData = {
                ...pageTrackingData,
                ...additionalTrackingData,
                ...rest,
            } as MappedAdditionalTrackingData

            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'view.ivw',
                ivw_cp: trackingData.page_ivw_code,
                ...rest,
            })

            if (loadAllAdSlots) {
                try {
                    // eslint-disable-next-line no-console
                    console.debug('Ads: SDG.Publisher?.loadAllSlots?.(true)')
                    window.SDG?.Publisher?.loadAllSlots?.(true)
                } catch (ignore) {}
            }
        },
        [pageTrackingData]
    )

    const trackAdView = useCallback(
        (data: MappedAdditionalTrackingData) => {
            const { additionalTrackingData, ...rest } = data
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'ad.impression',
                ...pageTrackingData,
                ...additionalTrackingData,
                ...rest,
            })
        },
        [pageTrackingData]
    )

    const trackAdViewOncePerPageView = useCallback(
        (data: MappedAdditionalTrackingData) => {
            const { creation, variant, advertiser_id } = data
            if (creation && variant && advertiser_id) {
                const key = `${advertiser_id}_${creation}_${variant}`
                if (!teaserImpressionKeys.current.includes(key)) {
                    teaserImpressionKeys.current.push(key)
                    trackAdView(data)
                }
            }
        },
        [trackAdView]
    )

    const trackAdInteraction = useCallback(
        (data: MappedAdditionalTrackingData) => {
            const { additionalTrackingData, ...rest } = data
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'ad.click',
                ...pageTrackingData,
                ...additionalTrackingData,
                ...rest,
            })
        },
        [pageTrackingData]
    )

    const trackInteraction = useCallback(
        (additionalTrackingData?: MappedTrackingData, ignorePageTrackingData = false) => {
            const eventPageTrackingData = !ignorePageTrackingData
                ? { ...pageTrackingData, ...additionalTrackingAdData }
                : {}
            const event = {
                event: 'interaction',
                ...eventPageTrackingData,
                ...additionalTrackingData,
            } as MappedAdditionalTrackingData
            event.interaction_label = event.interaction_label || event.page_name
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(event)
        },
        [pageTrackingData, additionalTrackingAdData]
    )

    const trackCampaign = useCallback(
        (data: CampaignCallbackData) => {
            const { additionalTrackingData, ...rest } = data
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                ...pageTrackingData,
                ...additionalTrackingData,
                ...rest,
            })
        },
        [pageTrackingData]
    )

    React.useEffect(() => {
        if (initial.current && Object.keys(trackingData).length > 0) {
            initial.current = false
            trackIvwInfo()
            trackPageView()
        }
    }, [trackIvwInfo, trackPageView, trackingData])

    return (
        <TrackingContext.Provider
            value={{
                trackingData,
                trackIvwView,
                trackIvwInfo,
                trackInteraction,
                trackCampaign,
                trackTeaserImpression,
                trackTeaserClick,
                trackAdView,
                trackAdViewOncePerPageView,
                trackAdInteraction,
            }}
        >
            {children}
        </TrackingContext.Provider>
    )
}

export const useTracking = (): TrackingState => useContext<TrackingState>(TrackingContext)
