import {
    ArticleSectionContentProps,
    ArticleSectionProps,
    ComponentType,
    LayoutProps,
} from '@sport1/types/web'

/**
 * Make a nice list of all content sections.
 * This helps when looking for things.
 */
export default function getArticleContents(
    layoutProps: LayoutProps | undefined
): ArticleSectionContentProps[] {
    return (
        (layoutProps?.components as unknown as ArticleSectionProps[])?.reduce(
            (
                contents: ArticleSectionContentProps[],
                section: ArticleSectionProps
            ): ArticleSectionContentProps[] => {
                if (section.type === ComponentType.ARTICLE_SECTION) {
                    return [...contents, ...section.content]
                }
                return contents
            },
            []
        ) || []
    )
}
