import { ArticleSectionType, LayoutProps } from '@sport1/types/web'
import { Video } from '@/types/video'
import getArticleContents from '@/utils/article/getArticleContents'
import getArticleSectionText from '@/utils/article/getArticleSectionText'
import { findValueByKey } from '@/utils/meta/findValue'
import getTrackingValue from '@/utils/tracking/getTrackingValue'

const fallbackTitle = 'SPORT1 - TV-Livestream, Programm & Empfang | SPORT1'

export default function getTitle({
    layoutProps,
    video,
}: {
    layoutProps?: LayoutProps
    video?: Video
}): string {
    return (
        findValueByKey(layoutProps?.meta?.seo, 'seo_title') ||
        getTrackingValue(layoutProps?.meta?.tracking, 'page_title') ||
        getArticleSectionText(getArticleContents(layoutProps), ArticleSectionType.HEADLINE) ||
        video?.seoTitle ||
        fallbackTitle
    )
}
