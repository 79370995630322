import { MetaDataItem } from '@sport1/types/web'

export const findValueByKey = (data: MetaDataItem | undefined, key: string): string | undefined =>
    data?.find(item => item.key === key)?.value

/**
 * ONE item for MetaData looks like this
 * [
 *     { key: 'name', value: 'robots' },
 *     { key: 'content', value: 'index, follow' },
 *  ]
 */
export const findValueByKeyValue = (
    data: MetaDataItem[] | undefined,
    key: string,
    value: string
): string | undefined => {
    const foundEntry = data?.find(entry => entry[0]?.key === key && entry[0]?.value === value)
    if (!foundEntry) {
        return undefined
    }
    return foundEntry[1]?.value
}
