import Config from '@/utils/Config'
import { ScreenSizesAsBoolean, Size, SizeTypes } from '@/types/size'

// according to the tailwind.config.js
export const BREAKPOINT_SCREEN_SMALL = 768
export const BREAKPOINT_SCREEN_MEDIUM = 1024
export const BREAKPOINT_SCREEN_LARGE = 1200

export const sizesToBoolean = (size: Size): ScreenSizesAsBoolean => ({
    isDesktop: size === SizeTypes.DESKTOP,
    isTablet: size === SizeTypes.TABLET,
    isMobile: size === SizeTypes.MOBILE,
})

export const BreakPointDisplaySizes = [
    SizeTypes.MOBILE,
    SizeTypes.MOBILE,
    SizeTypes.TABLET,
    SizeTypes.DESKTOP,
    SizeTypes.DESKTOP,
]

export const isMobileView = (): boolean => {
    /**
     * TODO: should not be used ssr. Use useMobile hook instead
     */
    return typeof window !== 'undefined' && window.innerWidth <= Config.MOBILE_THRESHOLD
}

export const isTouchDevice = (): boolean => {
    return (
        typeof window !== 'undefined' && ('ontouchstart' in window || navigator.maxTouchPoints > 0)
    )
}

/**
 * Apple Devices have Home Button on screen, which covers web page content.
 * here by we try to detect those devices, for example for BottomNavigation
 */
export const isAppleMobileDevice = (): boolean => {
    const appleReg = /iPhone|iPad/i
    return (
        typeof window !== 'undefined' &&
        (appleReg.test(window.navigator.userAgent) || appleReg.test(window.navigator.platform))
    )
}

export const getSizeType = (): SizeTypes | undefined => {
    if ('undefined' === typeof window) {
        return undefined
    }

    if (window.innerWidth >= BREAKPOINT_SCREEN_MEDIUM) {
        return SizeTypes.DESKTOP
    }

    if (window.innerWidth >= BREAKPOINT_SCREEN_SMALL) {
        return SizeTypes.TABLET
    }

    return SizeTypes.MOBILE
}
