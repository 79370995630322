import { ThemeProps } from 'styled-components'
import { SizeTypes } from '@/types/size'

type BreakpointMap = { key: SizeTypes; value: number }
export type BreakPointProps = { breakpoints: string[] }
export type BreakPointsThemeProps = ThemeProps<BreakPointProps>

export function getValueByBreakpoint<T>(values: T[], theme: BreakPointProps, defaultValue?: T): T {
    let resultValue = defaultValue || values[0]
    const breakpoints = theme.breakpoints.map(breakpoint => parseInt(breakpoint, 10))
    if (typeof window !== 'undefined') {
        const width = window.innerWidth
        breakpoints.forEach((breakpoint, index) => {
            if (width >= breakpoint && values.length > index) {
                resultValue = values[index]
            }
        })
    }

    return resultValue
}

// return devices with it's corresponding breakpoints in format {key: SizeTypes.TABLET, value: 768}
export function getBreakpointsSizes(sizes: SizeTypes[], theme: BreakPointProps): BreakpointMap[] {
    const breakpoints = theme.breakpoints.map(breakpoint => parseInt(breakpoint, 10))

    return breakpoints.map((breakpoint, index) => ({
        key: sizes[index],
        value: breakpoint,
    }))
}

// get first value of a device and return it's value
export function getBreakpointsStart(size: SizeTypes, breakpoints: BreakpointMap[]): number {
    return breakpoints.filter(breakpoint => breakpoint.key === size)[0].value
}
