import React from 'react'

function useMediaQuery(query: string): boolean {
    const [matches, setMatches] = React.useState(false)

    React.useEffect(() => {
        const media = window.matchMedia(query)

        setMatches(media.matches)
        const handler = (event: MediaQueryListEvent) => setMatches(event.matches)

        if (media.addEventListener) {
            media.addEventListener('change', e => {
                handler(e)
            })
            return () => media.removeEventListener('change', handler)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return matches
}

export default useMediaQuery
