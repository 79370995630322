export enum VENDOR_IDS {
    FACEBOOK = '89',
}
export enum CUSTOM_VENDOR_IDS {
    FACEBOOK_CONTENT = '5ee91b9593fc094b59242e25',
    INSTAGRAM = '5e717c8e69966540e4554f05',
    TWITTER = '5e71760b69966540e4554f01',
    YOUTUBE = '5e7ac3fae30e7d1bc1ebf5e8',
    TWITCH = '5ec85c9cb8e05c4a2002f42e',
    ABTASTY = '5f4fc0eb9c759936073f44b4',
    DAZN = '5ed7ab998ebb4404e1b15e50',
    SOM = '5f6dbc27a22863aa00420321',
    MIP = '5f2a2b392b87b72624a333f2',
    ADDEFEND = '5e98e7f1b8e05c48537f6114',
    SPOTIFY_AB = '5e7ad2ef1affb21bd098ccef',
    PINPOLL = '5e7ced57b8e05c485246cce3',
}

export const socialVendors = [
    CUSTOM_VENDOR_IDS.FACEBOOK_CONTENT,
    CUSTOM_VENDOR_IDS.INSTAGRAM,
    CUSTOM_VENDOR_IDS.TWITTER,
    CUSTOM_VENDOR_IDS.YOUTUBE,
    CUSTOM_VENDOR_IDS.TWITCH,
    CUSTOM_VENDOR_IDS.SPOTIFY_AB,
]

export const VENDOR_ID_TO_CMP_TAG: Record<string, string> = {
    [VENDOR_IDS.FACEBOOK]: 'cmp_facebook',
    [CUSTOM_VENDOR_IDS.FACEBOOK_CONTENT]: 'cmp_facebook_content',
    [CUSTOM_VENDOR_IDS.INSTAGRAM]: 'cmp_instagram',
    [CUSTOM_VENDOR_IDS.ABTASTY]: 'cmp_abtasty',
    [CUSTOM_VENDOR_IDS.ADDEFEND]: 'cmp_addefend',
    [CUSTOM_VENDOR_IDS.TWITTER]: 'cmp_twitter',
    [CUSTOM_VENDOR_IDS.YOUTUBE]: 'cmp_youtube',
    [CUSTOM_VENDOR_IDS.TWITCH]: 'cmp_twitch',
    [CUSTOM_VENDOR_IDS.DAZN]: 'cmp_dazn',
    [CUSTOM_VENDOR_IDS.MIP]: 'cmp_mip',
    [CUSTOM_VENDOR_IDS.SOM]: 'cmp_som',
    [CUSTOM_VENDOR_IDS.PINPOLL]: 'cmp_pinpoll',
    [CUSTOM_VENDOR_IDS.SPOTIFY_AB]: 'cmp_spotify_ab',
}
