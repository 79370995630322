import { MetaDataProps } from '@sport1/types/web'

export default function getErrorMeta({ path }: { path?: string }): MetaDataProps {
    return {
        tracking: [
            {
                key: 'page_type',
                value: 'error',
            },
            {
                key: 'page_name',
                value: `${path}`,
            },
            {
                key: 'page_title',
                value: 'Error',
            },
            {
                key: 'page_slug',
                value: `${path}`,
            },
            {
                key: 'page_language',
                value: 'de',
            },
            {
                key: 'page_withvideo',
                value: 'false',
            },
            {
                key: 'page_ivw_code',
                value: 'error',
            },
        ],
    }
}
