import {
    AdPlacementType,
    AdProps,
    AdType,
    ArticleAdProps,
    ComponentType,
    LayoutComponentProps,
    ViewPortSize,
    TeaserProps,
} from '@sport1/types/web'
import {
    EnrichableAd,
    FilterTeaserCardsProps,
} from '@/context/AdPlacementContext/AdPlacementProvider.types'
import { MappedAdPlacement, PlacementCount } from '@/utils/ads/display/createAdsPlacements.types'
import { isMobileView } from '@/helpers/pageHelper'

export const MAX_VALUES_FOR_ADS: Partial<PlacementCount> = {
    rectangle: 5,
    topmobile: 5,
    sky: 1,
}

export const isCorrectSize = (ad: AdProps | ArticleAdProps | TeaserProps, isMobile: boolean) =>
    ad.type !== ComponentType.AD ||
    !ad.viewPortSize ||
    (isMobile ? ad.viewPortSize === ViewPortSize.MEW : ad.viewPortSize === ViewPortSize.DESKTOP)

export function isTeaserType(component: LayoutComponentProps) {
    return (
        !component.lazy &&
        (component.type === ComponentType.TEASER_CARD ||
            component.type === ComponentType.TOP_TEASER_CARD ||
            component.type === ComponentType.EDITORIAL_HERO_CARD)
    )
}

export function shouldEnrichAd(component: EnrichableAd) {
    const { ad, enrichedWithId } = component
    const { adType, placement } = ad || {}
    const isMobile = isMobileView()
    const isDisplayAd = adType === AdType.DISPLAY

    const isMrecOrEventAd =
        placement === AdPlacementType.MREC || placement === AdPlacementType.EVENT_AD
    const isBannerOrEventTopAd =
        isMobile &&
        (placement === AdPlacementType.BANNER || placement === AdPlacementType.EVENT_TOP_AD)

    return (
        isDisplayAd &&
        !enrichedWithId &&
        isCorrectSize(component, isMobile) &&
        (!placement || isMrecOrEventAd || isBannerOrEventTopAd)
    )
}

export function filterTeaserCardAds(
    components: FilterTeaserCardsProps | FilterTeaserCardsProps[]
): AdProps[] {
    if (!Array.isArray(components)) {
        return (
            components.content?.filter(
                (content): content is AdProps => content.type === ComponentType.AD
            ) || []
        )
    }
    return components.flatMap(
        component =>
            component.content?.filter(
                (content): content is AdProps => content.type === ComponentType.AD
            ) || []
    )
}

export const getPlacementSuffix = (variationNumber: number) =>
    variationNumber > 1 ? `${variationNumber}` : ''

export function canAdSlotBeCreated(
    placementCounter: PlacementCount,
    adPlacementType: MappedAdPlacement
) {
    const adToBeAdded = placementCounter[adPlacementType]
    const maxValueForAdPlacement = MAX_VALUES_FOR_ADS[adPlacementType]

    return maxValueForAdPlacement ? adToBeAdded + 1 <= maxValueForAdPlacement : true
}
