import { Metric, MetricWithAttribution, FCPAttribution } from 'web-vitals'

interface CWVEventParams {
    event: Metric['name']
    value: Metric['delta']
    metric_id: Metric['id']
    metric_value: Metric['value']
    metric_delta: Metric['delta']
    debug_target?: string
    presentation_delay?: number
    input_delay?: number
    processing_duration?: number
    url?: string
    initiator_type?: string
    loadState?: FCPAttribution['loadState']
}

export const sendToGoogleAnalytics = ({
    name,
    delta,
    value,
    id,
    attribution,
}: MetricWithAttribution) => {
    const eventParams: CWVEventParams = {
        event: name,
        value: delta,
        metric_id: id,
        metric_value: value,
        metric_delta: delta,
    }

    switch (name) {
        case 'CLS':
            // left for debugging purposes if you need more info
            // console.log(`${name}`,attribution)
            eventParams.debug_target = attribution?.largestShiftTarget
            break
        case 'INP':
            // left for debugging purposes if you need more info
            // console.log(`${name}`,attribution)
            eventParams.debug_target = attribution?.interactionTarget
            eventParams.presentation_delay = attribution?.presentationDelay
            eventParams.input_delay = attribution?.inputDelay
            eventParams.processing_duration = attribution?.processingDuration
            break
        case 'LCP':
            // left for debugging purposes if you need more info
            // console.log(`${name}`,attribution)
            eventParams.debug_target = attribution?.element
            eventParams.url = attribution?.url
            eventParams.initiator_type = attribution?.lcpResourceEntry?.initiatorType
            break
        case 'FCP':
            // left for debugging purposes if you need more info
            // console.log(`${name}`,attribution)
            eventParams.loadState = attribution?.loadState
            break
    }
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(eventParams)
}
