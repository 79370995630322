import React, { createContext, FC, PropsWithChildren, useContext } from 'react'
import { ComponentType, LayoutProps, LayoutComponentProps, TeaserProps } from '@sport1/types/web'
import { getTag } from '@/helpers/renderBaseChannelComponent/utils'

interface MappedRedundantTeaserId {
    [teaserId: string]: { delete: boolean; index: number | undefined }
}

interface MappedRedundantTitles {
    [title: string]: { delete: boolean; index: number }
}

export type RedundantTeaserState = {
    redundantTeaserIds?: MappedRedundantTeaserId
    redundantTeaserTitles?: MappedRedundantTitles
    removeTeaserIds: (
        layout?: LayoutProps,
        deleteValues?: boolean,
        pageTagContextId?: string
    ) => LayoutProps
}

const RedundantContext = createContext<RedundantTeaserState>({
    redundantTeaserIds: {},
    redundantTeaserTitles: {},
    removeTeaserIds: (): LayoutProps => ({ components: [] }),
})

const componentRedundantWhiteList = [
    ComponentType.BUNDESLIGA_HUB,
    ComponentType.HORIZONTAL_CARD,
    ComponentType.TEASER_CARD,
    ComponentType.TOP_TEASER_CARD,
]

const RedundantTeaserProvider: FC<PropsWithChildren> = ({ children }) => {
    const redundantTeaserIds: MappedRedundantTeaserId = {}
    const redundantTeaserTitles: MappedRedundantTitles = {}

    /**
     * Filters out duplicate content components from a given layout.
     *
     * Sometimes this method does not receive the components in the same order as displayed on the website.
     * This is caused by lazy loading and multi page layouts.
     * Therefore, the components fragmentIndex is used to determine the display order,
     * and if the already processed component is located below the new component, the duplicate will **not** be removed.
     *
     * @param layout The layout from that duplicate content components are removed
     * @param deleteValues
     * If a layout has w.l.o.g 2 pages, the PageContentLayout component calls removeTeaserIds(...) twice:
     * First for the components of page one. Then again for the components of page one and two combined.
     * In order to not falsely detect page one components as duplicates in the second call, deleteValues has to be true.
     * This marks the new components for later deletion
     * and deletes existing marked components from the duplicate list before filtering.
     * @param pageTagContextId String containing information about page tag context ID
     */
    const removeTeaserIds = (
        layout?: LayoutProps,
        deleteValues = false,
        pageTagContextId?: string
    ): LayoutProps => {
        if (deleteValues) {
            Object.keys(redundantTeaserIds).forEach(id => {
                if (redundantTeaserIds[id].delete) {
                    delete redundantTeaserIds[id]
                }
            })
            Object.keys(redundantTeaserTitles).forEach(id => {
                delete redundantTeaserTitles[id]
            })
        }
        return {
            ...layout,
            components:
                layout?.components
                    ?.map(component => {
                        if (
                            (component as { content: TeaserProps[] }).content &&
                            componentRedundantWhiteList.includes(component.type)
                        ) {
                            const { content } = component as { content: TeaserProps[] }
                            const currentContentsIds = new Set(content.map(c => c.id))
                            return {
                                ...component,
                                content: content
                                    .filter(
                                        (teaser, index, self) =>
                                            /**
                                             * Here we filter dual occurrences of articles in one dashboard
                                             * but not with the same teaser id.
                                             */
                                            index ===
                                            self.findIndex(
                                                sTeaser =>
                                                    sTeaser.id === teaser.id &&
                                                    sTeaser.url === teaser.url
                                            )
                                    )
                                    .map((teaserContent, index) => {
                                        /* Two of the component types in the componentRedundantWhiteList allow to limit the
                                       number of visible elements. Elements that are hidden by this mechanism do not
                                       have to be filtered out and shouldn't cause other elements to be filtered out.
                                       Hence, they are skipped.
                                    */
                                        if (
                                            (component.type === ComponentType.TOP_TEASER_CARD ||
                                                component.type === ComponentType.TEASER_CARD) &&
                                            component.numberOfContentsToShow &&
                                            index >= component.numberOfContentsToShow
                                        ) {
                                            return teaserContent
                                        }

                                        const id = teaserContent.id
                                        if (id) {
                                            if (!currentContentsIds.delete(id)) {
                                                return null //remove duplicates within the current component
                                            }
                                            if (
                                                redundantTeaserIds[id] && // found duplicate teaser id
                                                (!component.fragmentIndex ||
                                                    !redundantTeaserIds[id].index ||
                                                    component.fragmentIndex >
                                                        (redundantTeaserIds[id].index as number))
                                            ) {
                                                return null //remove duplicate if fragmentIndex is bigger (or undefined)
                                            }
                                            redundantTeaserIds[id] = {
                                                delete: deleteValues,
                                                index: component.fragmentIndex,
                                            }
                                        }
                                        return teaserContent
                                    })
                                    .filter(cont => cont !== null),
                            } as LayoutComponentProps
                        }
                        return component
                    })
                    .filter(component => {
                        if (
                            component.type === ComponentType.TEASER_CARD ||
                            component.type === ComponentType.HORIZONTAL_CARD
                        ) {
                            const componentTag = getTag(component?.tags)
                            const hideHeadline =
                                componentTag?.contextId &&
                                componentTag.contextId === pageTagContextId
                            const title = !hideHeadline ? componentTag?.title.toString() : undefined
                            if (title && component.fragmentIndex) {
                                if (
                                    redundantTeaserTitles[title] &&
                                    redundantTeaserTitles[title].index !== component.fragmentIndex
                                ) {
                                    return false
                                }
                                redundantTeaserTitles[title] = {
                                    index: component.fragmentIndex,
                                    delete: false,
                                }
                            }
                        }

                        return true
                    }) || [],
        }
    }

    return (
        <RedundantContext.Provider
            value={{
                redundantTeaserIds,
                redundantTeaserTitles,
                removeTeaserIds,
            }}
        >
            {children}
        </RedundantContext.Provider>
    )
}

export const useRedundantTeaserFunctions = (): RedundantTeaserState =>
    useContext<RedundantTeaserState>(RedundantContext)

export default RedundantTeaserProvider
