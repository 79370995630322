export const CAMPAIGN_KEY = 'campaign'
export const VARIANT_KEY = 'variant'
export const FORMAT_KEY = 'format'
export const GENERAL_PLACEMENT_KEY = 'general_placement'
export const DETAILED_PLACEMENT_KEY = 'detailed_placement'
export const ADVERTISER_ID_KEY = 'advertiser_id'
export const CREATION_KEY = 'creation'
export const URL_KEY = 'target_url'

export const adTrackingExtraData = [{ setKey: 'content_type', searchKey: 'contentType' }]
