/**
 * check if sessionStorage exists and is allowed to be accessed
 */
export default function hasStorage(): boolean {
    try {
        if (window.sessionStorage && window.localStorage) {
            return true
        }
    } catch (ignore) {}
    return false
}
