import React, { FC, ReactElement, useCallback, useContext, useState } from 'react'
import { CUSTOM_VENDOR_IDS, socialVendors, VENDOR_IDS } from '@/utils/consent/consentVendors'

type CustomVendor = {
    purposeGrants?: Record<string, boolean>
    vendorGrant?: boolean
}
export interface ConsentInfo {
    consent_info: 'on' | 'off'
    vendors?: Record<string, boolean>
    customVendors?: Record<string, CustomVendor>
}

const states = [
    '5f041dcacdc94288b57cb600', //dev
    '5f23f302b4eaff1f7923df2e', // live
]

export type TCData = {
    eventStatus: string
    listenerId: number
    vendor: {
        consents: Record<string | number, boolean>
    }
}

const initialConsentInfo: ConsentInfo = { consent_info: 'off' }
type ConsentInfoState = {
    consentInfo: ConsentInfo
    getConsentById: (vendorType: 'standard' | 'custom', vendorId: string) => boolean
    publishConsent: (vendorId: VENDOR_IDS | CUSTOM_VENDOR_IDS) => void
    publishAllSocialConsent: () => void
    updateConsentInfo: (tcData: TCData) => void
}
export const ConsentInfoContext = React.createContext<ConsentInfoState>({
    consentInfo: initialConsentInfo,
    getConsentById: () => false,
    publishConsent: () => undefined,
    publishAllSocialConsent: () => undefined,
    updateConsentInfo: () => undefined,
})

type Props = {
    children: ReactElement
}

const ConsentInfoProvider: FC<Props> = ({ children }) => {
    const [providerInitiated, setProviderInitiated] = useState(false)
    const [listnerId, setListnerId] = useState(0)
    const [consentInfo, setConsentInfo] = React.useState<ConsentInfo>(initialConsentInfo)

    const fallback = React.useCallback(
        (fallbackInfo: ConsentInfo = { consent_info: 'off' }) => {
            if (JSON.stringify(consentInfo) !== JSON.stringify(fallbackInfo)) {
                setConsentInfo(fallbackInfo)
            }
        },
        [consentInfo]
    )

    const updateConsentInfo = useCallback(
        (tcData: TCData) => {
            if (typeof window.__tcfapi === 'function') {
                // guard; we start several async functions, if one of them errs,
                // we return without ConsentInfo; if another one succeeds later,
                // we do not want to double resolve the Promise
                let calledBack = false
                const callback = (info: ConsentInfo) => {
                    if (!calledBack) {
                        calledBack = true
                        setConsentInfo(info)
                        setProviderInitiated(true)
                    }
                }

                const gotInfo = {
                    vendors: false,
                    customVendors: false,
                }
                // resolves if questions for consentInfo of customVendors AND vendors resolved
                const publish = (type: 'vendors' | 'customVendors', info: ConsentInfo) => {
                    gotInfo[type] = true
                    if (gotInfo.vendors && gotInfo.customVendors) {
                        callback(info)
                    }
                }

                const _consentInfo: ConsentInfo = {
                    consent_info: 'on',
                    vendors: tcData.vendor?.consents || {},
                    customVendors: {},
                }
                publish('vendors', _consentInfo)

                // ask for consentInfo of customVendors
                window.__tcfapi('getCustomVendorConsents', 2, (consents, success) => {
                    // no consent info because of error
                    if (!success) {
                        return fallback()
                    }
                    _consentInfo.customVendors = consents.grants
                    publish('customVendors', _consentInfo)
                })
            }
        },
        [fallback]
    )

    React.useEffect(() => {
        if (!listnerId) {
            if (typeof window.__tcfapi === 'function') {
                window.__tcfapi('addEventListener', 2, (tc: TCData, success: boolean) => {
                    setListnerId(tc.listenerId)
                    // no consent info because of error
                    if (!success) {
                        return fallback()
                    }

                    // no consent info because privacy manager is open
                    if ('cmpuishown' === tc.eventStatus) {
                        return fallback()
                    }

                    // when ask CMP for consent when Provider is not initiated
                    // or it got an eventStatus tcloaded
                    if (
                        !providerInitiated ||
                        'tcloaded' === tc.eventStatus ||
                        'useractioncomplete' === tc.eventStatus
                    ) {
                        updateConsentInfo(tc)
                    }
                })
            }
        }
    }, [fallback, listnerId, providerInitiated, updateConsentInfo])

    const __postConsent = useCallback(
        (vendorIds: string[]) => {
            if (typeof window.__tcfapi === 'function') {
                window.__tcfapi(
                    'postCustomConsent',
                    2,
                    tcData => {
                        updateConsentInfo(tcData)
                    },
                    vendorIds,
                    states,
                    []
                )
            }
        },
        [updateConsentInfo]
    )

    const getConsentById = useCallback(
        (vendorType: 'standard' | 'custom', vendorId: string): boolean => {
            if (vendorType === 'standard') {
                return consentInfo.vendors?.[vendorId] || false
            }
            return consentInfo.customVendors?.[vendorId]?.vendorGrant || false
        },
        [consentInfo.customVendors, consentInfo.vendors]
    )

    const publishConsent = useCallback(
        (vendorId: VENDOR_IDS | CUSTOM_VENDOR_IDS) => {
            vendorId && __postConsent([vendorId])
        },
        [__postConsent]
    )

    const publishAllSocialConsent = useCallback((): void => {
        __postConsent(socialVendors)
    }, [__postConsent])

    return (
        <ConsentInfoContext.Provider
            value={{
                consentInfo,
                getConsentById,
                publishConsent,
                publishAllSocialConsent,
                updateConsentInfo,
            }}
        >
            {children}
        </ConsentInfoContext.Provider>
    )
}

export const useConsentManager = (): ConsentInfoState =>
    useContext<ConsentInfoState>(ConsentInfoContext)

export default ConsentInfoProvider
