import { ArticleSectionContentProps, ArticleSectionType } from '@sport1/types/web'

/**
 * Find the text of a given section content type
 */
export default function getArticleSectionText(
    contents: ArticleSectionContentProps[],
    type: ArticleSectionType
): string | undefined {
    const section = contents.find(
        (content: ArticleSectionContentProps) => content.type === type
    ) as { text: string }
    return section?.text
}
