/**
 * If consent info is present -> call back with Consent
 * else call back without Consent
 * in cases of error call back without Consent
 *
 * callback may trigger multiple times, as more information arrives
 */

import { ConsentInfo, TCData } from '@/utils/consent/ConsentInfoProvider'
import { VENDOR_ID_TO_CMP_TAG } from '@/utils/consent/consentVendors'

export default function observeConsentInfo(callback: { (consentInfo: ConsentInfo): void }): void {
    if (typeof window.__tcfapi !== 'function') {
        return
    }
    window.__tcfapi('addEventListener', 2, (tcData: TCData, success: boolean) => {
        // no consent info because of error
        if (!success) {
            callback({ consent_info: 'off' })
        }

        // no consent info because privacy manager is open
        if ('cmpuishown' === tcData.eventStatus) {
            callback({ consent_info: 'off' })
        }

        if ('tcloaded' === tcData.eventStatus || 'useractioncomplete' === tcData.eventStatus) {
            const consentInfo = { consent_info: 'on' }

            // ask for consentInfo of vendors
            Object.keys(tcData.vendor.consents).forEach(vendor => {
                const cmpTag = VENDOR_ID_TO_CMP_TAG[vendor]
                if (cmpTag) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    consentInfo[cmpTag] = tcData.vendor.consents[vendor]
                }
            })
            callback(consentInfo as ConsentInfo)

            // ask for consentInfo of customVendors
            window.__tcfapi(
                'getCustomVendorConsents',
                2,
                (consents, successfulGetCustomVendorConsents) => {
                    // no consent info because of error
                    if (!successfulGetCustomVendorConsents) {
                        callback({ consent_info: 'off' })
                    } else {
                        Object.keys(consents.grants).forEach(vendor => {
                            const cmpTag = VENDOR_ID_TO_CMP_TAG[vendor]
                            if (cmpTag) {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                consentInfo[cmpTag] = consents.grants[vendor].vendorGrant
                            }
                        })
                        callback(consentInfo as ConsentInfo)
                    }
                }
            )
        }
    })
}
