import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.extend(duration)
dayjs.extend(customParseFormat)
dayjs.tz.setDefault('Europe/Berlin')

// this module would be removed by webpack since it is not exporting
// anything.
// but it has sideEffects, so let's force keeping it by exporting this
// dummy function.
// eslint-disable-next-line @typescript-eslint/no-empty-function
export default function extend() {}
