import React, { FC } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Config from '@/utils/Config'

type DeepLinkProps = {
    deeplink?: string
}

const IOS_APP_ID = '300000385'

const DeepLink: FC<DeepLinkProps> = ({ deeplink }) => {
    const router = useRouter()

    let finalDeeplink = deeplink
    if (!finalDeeplink && router.asPath && router.asPath !== '/') {
        finalDeeplink = `sport1://weburl/${Config.HOST.replace(/.*\//, '')}${router.asPath}`
    }

    return finalDeeplink ? (
        <Head>
            <meta
                name="apple-itunes-app"
                content={`app-id=${IOS_APP_ID}, app-argument=${finalDeeplink}`}
            />
            <meta property="al:ios:url" content={finalDeeplink} />
            <meta property="al:android:url" content={finalDeeplink} />
            <meta property="al:windows_universal:url" content={finalDeeplink} />
        </Head>
    ) : (
        <Head>
            <meta name="apple-itunes-app" content={`app-id=${IOS_APP_ID}`} />
        </Head>
    )
}

export default DeepLink
