import React, { createContext, ReactElement, useCallback, useContext } from 'react'
import {
    ArticleImageProps,
    AdProps,
    GalleryTeaserAssetProps,
    GalleryTeaserProps,
    HeroImageProps,
} from '@sport1/types/web'
import dynamic from 'next/dynamic'
import { SearchBarProps } from '@/layouts/Standard/Header/components/HeaderBanner/components/Search'

const ImageOverlay = dynamic(() => import('@/utils/overlay/ImageOverlay'), { ssr: false })
const GalleryOverlay = dynamic(() => import('@/utils/overlay/GalleryOverlay'), { ssr: false })
const SearchOverlay = dynamic(() => import('@/utils/overlay/SearchOverlay'), { ssr: false })

type ImageOverlayProps = {
    type: 'image'
    content: ArticleImageProps | HeroImageProps
}
type GalleryOverlayProps = {
    type: 'gallery'
    content: {
        gallery: GalleryTeaserProps
        selectedAsset?: GalleryTeaserAssetProps | AdProps
    }
}

type SearchOverlayProps = {
    type: 'search'
    content: SearchBarProps
}
type Overlay = ImageOverlayProps | GalleryOverlayProps | SearchOverlayProps

export type OverlayState = {
    isOverlayOpen: boolean
    openOverlay: (overlay: Overlay) => void
}

const OverlayContext = createContext<OverlayState>({
    isOverlayOpen: false,
    openOverlay: () => undefined,
})

type OverlayProviderProps = {
    children?: ReactElement | ReactElement[]
}

export const OverlayProvider = ({ children }: OverlayProviderProps): ReactElement => {
    const [overlay, setOverlay] = React.useState<Overlay>()

    const closeOverlay = useCallback(() => {
        setOverlay(undefined)
    }, [])

    const renderOverlay = useCallback(() => {
        switch (overlay?.type) {
            case 'image':
                return <ImageOverlay content={overlay.content} closeOverlay={closeOverlay} />
            case 'gallery':
                return <GalleryOverlay content={overlay.content} closeOverlay={closeOverlay} />
            case 'search':
                return <SearchOverlay content={overlay.content} closeOverlay={closeOverlay} />
        }
    }, [closeOverlay, overlay])

    React.useEffect(() => {
        if (!!overlay) {
            const bodyStyle = document.body.style
            const oldHeight = bodyStyle.height
            const oldOverflow = bodyStyle.overflow
            bodyStyle.height = '100%'
            bodyStyle.overflow = 'hidden'
            return () => {
                bodyStyle.height = oldHeight
                bodyStyle.overflow = oldOverflow
            }
        }
    }, [overlay])

    return (
        <OverlayContext.Provider
            value={{
                isOverlayOpen: !!overlay,
                openOverlay: setOverlay,
            }}
        >
            <>
                {children}
                {renderOverlay()}
            </>
        </OverlayContext.Provider>
    )
}

export const useOverlay = (): OverlayState => useContext<OverlayState>(OverlayContext)
