import getConfig from 'next/config'

const Config = {
    ADS_ACTIVE: getConfig()?.publicRuntimeConfig?.ADS_ACTIVE,
    BETS_SERVICE_ENV: getConfig()?.publicRuntimeConfig?.BETS_SERVICE_ENV,
    CMS_API:
        (typeof window === 'undefined'
            ? getConfig()?.publicRuntimeConfig?.CMS_API_CORE
            : getConfig()?.publicRuntimeConfig?.CMS_API_EDGE) ?? 'https://api.sport1.info',
    CMSDATASERVICE: getConfig()?.publicRuntimeConfig?.CMSDATASERVICE,
    ELASTIC: getConfig()?.publicRuntimeConfig?.ELASTIC as string,
    GOOGLE_TAG_MANAGER_ID: getConfig()?.publicRuntimeConfig?.GOOGLE_TAG_MANAGER_ID,
    HOST: getConfig()?.publicRuntimeConfig?.HOST ?? 'https://www.sport1.de',
    HOST_TV: getConfig()?.publicRuntimeConfig?.HOST_TV ?? 'https://tv.sport1.de',
    JW_PLAYER: getConfig()?.publicRuntimeConfig?.JW_PLAYER || 'nrRgdCot',
    LIVESTREAM_ID: '591499',
    // gets forwarded by nginx for prod and stage
    // https://gitlab.sport1dev.de/ng/Outgest/Edge/website/-/blob/5dc593e8ee0af78cda543d476b205b85d15ddc3c/docker/nginx/templates/default.conf.template#L20-23
    METATAG:
        getConfig()?.publicRuntimeConfig?.METATAG ||
        'https://cdn.stroeerdigitalgroup.de/metatag/live/sport1/metaTag.min.js',
    META_DATA_SERVICE:
        getConfig()?.publicRuntimeConfig?.META_DATA_SERVICE ||
        'https://stage-metadataservice.sport1.de',
    MIDROLLS_AD_PROVIDER: getConfig()?.publicRuntimeConfig?.MIDROLLS_AD_PROVIDER || 'STROER',
    MOBILE_THRESHOLD: 767,
    OZ_API: (getConfig()?.publicRuntimeConfig?.OZ_API as string) ?? 'https://oz.sport1.de',
    PIANO_COMPOSER_ENVIRONMENT:
        getConfig()?.publicRuntimeConfig?.PIANO_COMPOSER_ENVIRONMENT || 'sandbox',
    RELEASE: (getConfig()?.publicRuntimeConfig?.RELEASE || 'development') as string,
    RESHAPE: (getConfig()?.publicRuntimeConfig?.RESHAPE as string) ?? 'https://reshape.sport1.de',
    ROBOTS_DENY: getConfig()?.publicRuntimeConfig?.ROBOTS_DENY === 'true',
    S1_COMPONENTS_ENVIRONMENT: getConfig()?.publicRuntimeConfig?.S1_COMPONENTS_ENVIRONMENT,
    SENTRY_DSN: getConfig()?.publicRuntimeConfig?.SENTRY_DSN,
    SENTRY_ENVIRONMENT: getConfig()?.publicRuntimeConfig?.SENTRY_ENVIRONMENT,
    SENTRY_RELEASE: getConfig()?.publicRuntimeConfig?.SENTRY_RELEASE,
    SENTRY_SAMPLE_RATE: getConfig()?.publicRuntimeConfig?.SENTRY_SAMPLE_RATE,
    SENTRY_TRACES_SAMPLE_RATE: getConfig()?.publicRuntimeConfig?.SENTRY_TRACES_SAMPLE_RATE,
    SOURCE_POINT_ACCOUNT_ID: getConfig()?.publicRuntimeConfig?.SOURCE_POINT_ACCOUNT_ID,
    SOURCE_POINT_BASE_ENDPOINT: getConfig()?.publicRuntimeConfig?.SOURCE_POINT_BASE_ENDPOINT,
    SOURCE_POINT_CAMPAIGN_ENV: getConfig()?.publicRuntimeConfig?.SOURCE_POINT_CAMPAIGN_ENV,
    SOURCE_POINT_PRIVACY_MANAGER_ID:
        getConfig()?.publicRuntimeConfig?.SOURCE_POINT_PRIVACY_MANAGER_ID,
    SOURCE_POINT_PROPERTY_HREF: getConfig()?.publicRuntimeConfig?.SOURCE_POINT_PROPERTY_HREF,
    SOURCE_POINT_PROPERTY_ID: getConfig()?.publicRuntimeConfig?.SOURCE_POINT_PROPERTY_ID,
    THIRDPARTY_LOAD_ATI: getConfig()?.publicRuntimeConfig?.THIRDPARTY_LOAD_ATI,
}

export default Config
