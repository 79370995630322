export enum SizeTypes {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
    TABLET = 'tablet',
}

export type Size = SizeTypes.DESKTOP | SizeTypes.MOBILE | SizeTypes.TABLET

export type ScreenSizesAsBoolean = {
    isDesktop: boolean
    isTablet: boolean
    isMobile: boolean
}
