import React, {
    createContext,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useRouter } from 'next/router'
import { ComponentType, LayoutComponentProps, LayoutProps } from '@sport1/types/web'
import {
    AdPlacement,
    AdPlacementContextProps,
    AdPlacementState,
    EnrichableAd,
    FilterTeaserCardsProps,
} from '@/context/AdPlacementContext/AdPlacementProvider.types'
import {
    canAdSlotBeCreated,
    filterTeaserCardAds,
    getPlacementSuffix,
    isTeaserType,
    shouldEnrichAd,
} from '@/context/AdPlacementContext/AdPlacementProvider.utils'
import { useMobile } from '@/hooks/useMobile'
import {
    createAdsPlacements,
    placementCounterDefault,
} from '@/utils/ads/display/createAdsPlacements'
import { AdSSetupPlacements } from '@/utils/ads/display/createAdsPlacements.types'
import { getAdKeys } from '@/utils/ads/display/getAdKeys'
import hasStorage from '@/utils/storage/hasStorage'

type AdPlacementProviderProps = {
    path?: string
    layoutData?: LayoutProps
    ads?: LayoutComponentProps[]
    children: ReactElement | ReactElement[] | null
}

const AdPlacementContext = createContext<AdPlacementContextProps>({
    placements: new Map<string, AdPlacement>(),
    enrichAdsWithId: () => undefined,
    adKeys: [],
    placementCounter: placementCounterDefault,
})

export const AdPlacementProvider = ({
    path = '',
    ads,
    layoutData,
    children,
}: AdPlacementProviderProps): React.ReactElement => {
    /**
     * TODO: Ströer
     */
    const finalized = useRef(false)

    const { isMobile } = useMobile()
    const { pathname } = useRouter()

    const [{ skyScraper, placements, placementCounter }, setAdPlacements] =
        useState<AdPlacementState>({
            placements: new Map<string | undefined, AdPlacement>(),
            placementCounter: { ...placementCounterDefault },
        })

    const { firstBillboard, adKeys } = useMemo(() => {
        const placements = createAdsPlacements({
            isMobile,
            path,
            ads,
            layoutData,
        }).adPlacements

        const placementsByDevice = {
            ...(isMobile ? {} : { desktop: placements }),
        }

        return {
            firstBillboard: {
                desktop: placementsByDevice?.desktop?.firstBillboard,
            },
            adKeys: getAdKeys(layoutData),
        }
    }, [ads, isMobile, layoutData, path])

    const enrichAdsWithId = useCallback(
        (layoutComponents: LayoutComponentProps[]) => {
            layoutComponents.forEach(layoutComponent => {
                let adComponents: EnrichableAd[] = []

                if (layoutComponent.type === ComponentType.AD) {
                    adComponents = [layoutComponent]
                } else if (isTeaserType(layoutComponent)) {
                    adComponents = filterTeaserCardAds(layoutComponent as FilterTeaserCardsProps)
                }

                adComponents.forEach(component => {
                    const shouldEnrichWithId = shouldEnrichAd(component)

                    if (shouldEnrichWithId) {
                        const adPlacementType = isMobile ? 'topmobile' : 'rectangle'
                        const isAdSlotAvailable = canAdSlotBeCreated(
                            placementCounter,
                            adPlacementType
                        )

                        if (!isAdSlotAvailable) {
                            /**
                             * TODO Stroeer (this is a quick workaround) noShow
                             * this prop removes unnecessary ads (related to ad limits on the site)
                             */
                            component.noShow = true
                            return
                        }
                        /**
                         * To avoid the case that the restriction of one placement blocked another
                         * (for example: topmobile and rectangle)
                         */
                        component.noShow = false
                        placementCounter[adPlacementType] += 1
                        const suffix = getPlacementSuffix(placementCounter[adPlacementType])
                        const adPlacementWithSuffix = `${adPlacementType}${suffix}`
                        component.id = adPlacementWithSuffix

                        component.enrichedWithId = true
                        placements.set(adPlacementWithSuffix, {
                            placement: adPlacementType,
                            placementId: adPlacementWithSuffix,
                        })
                    }
                })
            })
        },
        [isMobile, placementCounter, placements]
    )

    useEffect(() => {
        const beforeUnloadHandler = () => {
            window.SDG?.cmd.push(function () {
                window.SDG?.Publisher?.unregisterAllSlots?.()
                window.S1RegisteredSlots = {}
            })
        }

        window.addEventListener('beforeunload', beforeUnloadHandler)
    }, [])

    useEffect(() => {
        /**
         * These next two lines are a workaround. We need to count
         * placements, because their placementIds shall autoincrement.
         * See the function `getPlacementSuffix` for instance.
         *
         * Since lazy loading components might have ads in them, they
         * need to aware of these autoincrements of other components.
         * This is were this workaround comes in. `currentAdPlacements`
         * is a reference to a global variable. It gets initially filled
         * in the AdSSetup.tsx
         */
        const currentAdPlacements = window.adPlacements || ({} as AdSSetupPlacements)
        window.adPlacements = currentAdPlacements

        const placementCounter = currentAdPlacements.placementCounter
        const placementMap = new Map()
        if (currentAdPlacements.placements) {
            Object.keys(currentAdPlacements.placements).forEach(key => {
                placementMap.set(key, currentAdPlacements.placements[key])
            })
        }

        setAdPlacements({
            skyScraper: currentAdPlacements.skyScraper,
            placements: placementMap,
            placementCounter: placementCounter || {
                ...placementCounterDefault,
            },
        })

        if (!finalized.current) {
            const finalize = () => {
                /**
                 * TODO: Ströer, remove
                 */
                if (hasStorage() && window.localStorage.debug === 'ads') {
                    document.documentElement.classList.add('debug')
                }
                window.SDG?.cmd.push(function () {
                    // eslint-disable-next-line no-console
                    console.debug('Ads: SDG?.Publisher?.transitionAdvertisements?.()')
                    window.SDG?.Publisher?.transitionAdvertisements?.()
                    // eslint-disable-next-line no-console
                    console.debug('Ads: SDG?.Publisher?.finalizeSlots?.()')
                    window.SDG?.Publisher?.finalizeSlots?.()
                })
                finalized.current = true
            }

            if ('loading' === document.readyState) {
                window.addEventListener('DOMContentLoaded', finalize)
                return () => {
                    window.removeEventListener('DOMContentLoaded', finalize)
                }
            }
            finalize()
        }
    }, [pathname])

    return (
        <AdPlacementContext.Provider
            value={{
                firstBillboard,
                skyScraper,
                placements,
                enrichAdsWithId,
                adKeys,
                placementCounter,
            }}
        >
            {children}
        </AdPlacementContext.Provider>
    )
}

export const useAdPlacementContext = (): AdPlacementContextProps =>
    useContext<AdPlacementContextProps>(AdPlacementContext)
