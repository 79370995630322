import { ConsentInfo } from '@/utils/consent/ConsentInfoProvider'
import observeConsentInfo from '@/utils/consent/observeConsentInfo'
import hasStorage from '@/utils/storage/hasStorage'
import { CUSTOM_VENDOR_IDS, VENDOR_IDS } from '@/utils/consent/consentVendors'

// number of vendors to get information about, so that the promise can
// be resolved
const vendorMinLength = [
    ...new Set([...Object.values(VENDOR_IDS), ...Object.values(CUSTOM_VENDOR_IDS)]),
].length

const fallbackConsentInfo: ConsentInfo = { consent_info: 'off' }

/**
 * A function to get "good enough" consent info right away.
 *
 * Use case: The tracking event view.page passes consentInfo to the GTM.
 * The call to get consentInfo takes time (more than 3 secs, often
 * longer).
 *
 * ATI and IVW wait for view.page, but shall not suffer that delay.
 *
 * If sessionStorage is available, it is used to read from there RIGHT
 * AWAY; if no data are in the storage, the consent_info is off. The
 * sessionStorage gets updated later.
 *
 * This is not a function to check for Consent and render third parties.
 * Use the ConsentInfoProvider for that.
 */
export default function getConsentInfo(): Promise<ConsentInfo> {
    if (hasStorage()) {
        let consentInfo = fallbackConsentInfo
        try {
            consentInfo = JSON.parse(window.sessionStorage.consentInfo || 'null')
        } catch (ignore) {}

        observeConsentInfo(update)

        // return what was read from sessionStorage
        return Promise.resolve(consentInfo || fallbackConsentInfo)
    }

    // no sessionStorage? -> return Promise and wait for vendors
    return getCompleteConsentInfo()
}

const update = (consentInfo: ConsentInfo) => {
    window.sessionStorage.consentInfo = JSON.stringify(consentInfo)
}

const getCompleteConsentInfo = (): Promise<ConsentInfo> =>
    new Promise(resolve => {
        let resolved = false
        observeConsentInfo(consentInfo => {
            if (!resolved) {
                if (
                    consentInfo.consent_info === 'off' ||
                    Object.keys(consentInfo).length > vendorMinLength
                ) {
                    resolved = true
                    resolve(consentInfo)
                }
            }
        })
    })
