import { AdProps, LayoutProps } from '@sport1/types/web'

const getAdKeys = (layoutData?: LayoutProps): string[] => {
    if (layoutData?.meta?.ad?.adKeys) {
        return layoutData.meta.ad.adKeys
    }
    const componentWithAdKeys = layoutData?.components?.find(
        c => (c as AdProps).ad?.adKeys
    ) as AdProps

    return componentWithAdKeys?.ad?.adKeys ?? []
}

export { getAdKeys }
