import { useEffect, useState } from 'react'
import { BREAKPOINT_SCREEN_MEDIUM, BREAKPOINT_SCREEN_SMALL } from '@/helpers/pageHelper'

function isClientSide() {
    return typeof window !== 'undefined'
}

// TODO: Change useMobile name to smth like useResponsive and update project

const useMobile = (): { isMobile: boolean; isTablet: boolean; isDesktop: boolean } => {
    const [sizes, setSizes] = useState({ isMobile: false, isTablet: false, isDesktop: false })
    useEffect(() => {
        if (isClientSide()) {
            setSizes({
                isMobile: window.innerWidth <= BREAKPOINT_SCREEN_SMALL,
                isTablet:
                    window.innerWidth >= BREAKPOINT_SCREEN_SMALL &&
                    window.innerWidth <= BREAKPOINT_SCREEN_MEDIUM,
                isDesktop: window.innerWidth >= BREAKPOINT_SCREEN_MEDIUM,
            })
        }
    }, [])

    return sizes
}

export { useMobile }
